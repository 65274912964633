import { useState, useEffect, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Page from "../../components/common/page/Page";
import ArtistLayout from "../../layouts/artistLayout/ArtistLayout";
import { CreateArtistForm } from "../../forms/createArtistForm/CreateArtistForm";
import CountryList from "country-list-with-dial-code-and-flag";
import { createArtistValidate } from "../../validates/createArtist";
import { createArtist, getArtistById } from "../../api/artist";

const ArtistInformationPage = () => {
  const navigate = useNavigate();
  const [artistId, setArtistId] = useState("");

  const {
    mutate,
  } = useMutation((payload) => {
    return createArtist(payload);
  });

  const { data: artistData, isLoading } = useQuery(
    ["artist-Id", artistId],
    () => getArtistById({ artistId }),
    {
      enabled: !!artistId,
    }
  );

  const handleSubmit = useCallback(
    (values, { setSubmitting, setErrors }) => {
      // Create payload and filter out empty fields
      const payload = {
        ...values,
        phoneNumber: values.phoneNumber.toString(),
        profileImageUrl: values.image,
      };
      delete payload.image;

      // Fields to omit if they are empty
      const fieldsToOmit = [
        "email",
        "facebookUrl",
        "instagramUrl",
        "videoUrl",
        "twitterUrl",
        "website",
        "phoneNumber"
      ];

      fieldsToOmit.forEach((field) => {
        if (!payload[field]) {
          delete payload[field];
        }
      });

      mutate(
        { value: payload },
        {
          onSuccess: () => {
            enqueueSnackbar("User is successfully created", {
              variant: "success",
            });
            navigate("/artists");
            setSubmitting(false);
          },
          onError: (error) => {
            const errorData = error?.response?.data?.message
            enqueueSnackbar(`${errorData}`, { variant: "error" })
            if (errorData) {
              const formErrors = {};
              errorData.forEach((err) => {
                const field = "error"
                formErrors[field] = err;
              });
              setErrors(formErrors);
            }
            setSubmitting(false);
          },
        }
      );
    },
    [mutate, navigate]
  );

  const formattingPhoneData = () => {
    if (artistData) {
      const code = CountryList.getAll().find(
        ({ data }) => data?.dial_code === artistData?.data?.countryCode
      );

      if (!code) {
        return {
          code: "+27",
          phone: artistData?.data?.phoneNumber.toString(),
        };
      }

      if (code) {
        const { dial_code } = code;
        let replacePhone = "";

        if (dial_code) {
          replacePhone = artistData?.data?.phoneNumber.slice(dial_code.length);

          return {
            code: dial_code,
            phone: replacePhone.toString(),
          };
        }
      }
    }

    return {
      code: "+27",
      phone: "",
    };
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const id = params.get("id");

    if (id) {
      setArtistId(id);
    }
  }, []);

  return (
    <Page title="Artist Information">
      <Formik
        initialValues={{
          fullName: artistData?.data?.fullName || "",
          email: artistData?.data?.email || "",
          countryCode: formattingPhoneData()?.code || "",
          phoneNumber: formattingPhoneData()?.phone || "",
          recordLabel: artistData?.data?.recordLabel || "",
          website: artistData?.data?.website || "",
          facebookUrl: artistData?.data?.facebookUrl || "",
          instagramUrl: artistData?.data?.instagramUrl || "",
          twitterUrl: artistData?.data?.twitterUrl || "",
          stageName: artistData?.data?.stageName || "",
          coverImageUrl: artistData?.data?.coverImageUrl || "",
          biography: artistData?.data?.biography || "",
          image: artistData?.data?.profileImageUrl || "",
          videoUrl: artistData?.data?.videoUrl || "",
        }}
        validationSchema={createArtistValidate}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <ArtistLayout imgFieldName="image" imgType="imageType">
            <CreateArtistForm
              isLoading={isLoading}
              errors={errors}
              touched={touched}
            />
          </ArtistLayout>
        )}
      </Formik>
    </Page>
  );
};

export default ArtistInformationPage;
